import React, {useCallback, useRef, useState} from 'react';
import styled, {css, useTheme} from "styled-components";
import {Box, Flex, Heading, Text} from "rebass/styled-components";
import {sectionHeadingStyles} from "../Home/Home";
import {FaArrowLeft as ArrowBackIosIcon} from '@react-icons/all-files/fa/FaArrowLeft';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Arrow, NavIcon} from "../Home/components/TradeCarsSection/TradeCarsSection";
import {useTranslation} from "next-i18next";
import Image from 'next/image';

const allTestimonials = [
  {
    id: 1,
    state: 'collapsed',
    name: 'contactPageTestimonial1Title',
    image: '/static-web/images/contacts/bogdan.jpg',
    text: 'contactPageTestimonial1Content',
    expandedHeight: 600
  },
  {
    id: 2,
    state: 'collapsed',
    name: 'contactPageTestimonial2Title',
    image: '/static-web/images/contacts/andrii.jpg',
    text: 'contactPageTestimonial2Content',
    expandedHeight: 630
  },
  {
    id: 3,
    state: 'collapsed',
    name: 'contactPageTestimonial3Title',
    image: '/static-web/images/contacts/nazar.jpg',
    text: 'contactPageTestimonial3Content',
    expandedHeight: 630
  },
  {
    id: 4,
    state: 'collapsed',
    name: 'contactPageTestimonial4Title',
    image: '/static-web/images/contacts/vitalik.jpg',
    text: 'contactPageTestimonial4Content',
    expandedHeight: 600
  },
  {
    id: 5,
    state: 'collapsed',
    name: 'contactPageTestimonial5Title',
    image: '/static-web/images/contacts/yura.png',
    text: 'contactPageTestimonial5Content',
    expandedHeight: 730
  },
  {
    id: 6,
    state: 'collapsed',
    name: 'contactPageTestimonial6Title',
    image: '/static-web/images/contacts/andrii2.png',
    text: 'contactPageTestimonial6Content',
    expandedHeight: 500
  },
  {
    id: 7,
    state: 'collapsed',
    name: 'contactPageTestimonial7Title',
    image: '/static-web/images/contacts/taras.png',
    text: 'contactPageTestimonial7Content',
    expandedHeight: 680
  },
  {
    id: 8,
    state: 'collapsed',
    name: 'contactPageTestimonial8Title',
    image: '/static-web/images/contacts/olya.png',
    text: 'contactPageTestimonial8Content',
    expandedHeight: 450
  }
];

const ArrowDown = ({onClick}) => {
  return <ArrowBackIosIcon onClick={onClick}/>
}

const Contact = ({screenWidth: width}) => {
  const theme = useTheme();

  const { t } = useTranslation('common');

  const swiperRef = useRef(null);

  const laptopWidth = theme.breakpointsNumeric[2];
  const mobileWidth = theme.breakpointsNumeric[1];
  const smMobileWidth = theme.breakpointsNumeric[0];

  const [testimonials, setTestimonials] = useState(allTestimonials.map(t => ({ ...t, height: 380 })))

  const simulateCall = useCallback((number) => {
    window.open(`tel:${number}`, '_self')
  }, []);

  return (
    <>
      <Section
        isSmMobile={width <= smMobileWidth}
        id='section-1'
        fullWidth
        heading
        sx={{
          position: 'relative',
          maxHeight: '820px',
        }}
      >
        <ImageOverlay/>
        <ImageContainer
          sx={{
            minHeight: width <= mobileWidth ? 325 : 800
          }}
        >
          <CarHeaderImage
            priority
            alt='tesla'
            layout='fill'
            height={width <= mobileWidth ? 500 : 800}
            src='/static-web/images/contacts/car-header.jpg'
          />
        </ImageContainer>
      </Section>

      <Section
        isSmMobile={width <= smMobileWidth}
        id='section-2'
        fullWidth
      >
        <Text
          sx={{
            ...sectionHeadingStyles
          }}
        >
          <Heading
            as="h1"
            variant='heading.xl'
            color='text.secondary'
            fontFamily='secondary'
          >
            {t('contactPageTitle')}
          </Heading>

          <SubTitle
            sx={{
              fontFamily: 'primary',
              color: "#000",
              textTransform: 'initial'
            }}
          >
            {t('contactPageSubTitle')}
          </SubTitle>
        </Text>

        <PageContent>
          <TeamWrapper mt={10}>
            <TeamMember>
              <MemberImageWrapper>
                <MemberImage
                  layout='fill'
                  alt='Taras'
                  src='/static-web/images/contacts/TARAS/TARAS-cover.png'
                  // hoverUrl='/static-web/images/contacts/TARAS/TARAS.gif'
                />
              </MemberImageWrapper>
              <InfoBlock
                sx={{
                  borderLeft: '1px solid #000'
                }}
              >
                <Name>Тарас</Name>
                <Position>{t('contactPageMember1Position')}</Position>
                <Phone
                  onClick={() => {
                    simulateCall('+380962156759')
                  }}
                >
                  +38 (096) 215 67 59
                </Phone>
                <Experience><b>{t('contactPageMemberExperienceLabel')}</b> {t('contactPageMember1Experience')}</Experience>
                <Specialization>{t('contactPageMember1Description')}</Specialization>
                {/*<CarsCount><b>{t('contactPageMember1CarsCount')}</b> {t('contactPageMember1CarsCountLabel')}</CarsCount>*/}
              </InfoBlock>
            </TeamMember>
            <TeamMember>
              <MemberImageWrapper>
                <MemberImage
                  layout='fill'
                  alt='Ostap'
                  src='/static-web/images/contacts/ostap.jpg'
                />
              </MemberImageWrapper>
              <InfoBlock>
                <Name>Остап</Name>
                <Position>{t('contactPageMember2Position')}</Position>
                <Phone></Phone>
                <Experience><b>{t('contactPageMemberExperienceLabel2')}</b> {t('contactPageMember2Experience')}</Experience>
                <Specialization>{t('contactPageMember2Description')}</Specialization>
                {/*<CarsCount><b>{t('contactPageMember2CarsCount')}</b> {t('contactPageMember2CarsCountLabel')}</CarsCount>*/}
              </InfoBlock>
            </TeamMember>
            <TeamMember>
              <MemberImageWrapper>
                <MemberImage
                  alt='Ira'
                  layout='fill'
                  src='/static-web/images/contacts/ira.jpg'
                  // hoverUrl='/static-web/images/contacts/TARAS/TARAS.gif'
                />
              </MemberImageWrapper>
              <InfoBlock
                sx={{
                  borderLeft: '1px solid #000'
                }}
              >
                <Name>{t('contactPageManager1Title')}</Name>
                <Position>{t('contactPageManager1Position')}</Position>
                <Phone
                  onClick={() => {
                    simulateCall('+380962229054')
                  }}
                >
                  +38 (096) 222 90 54
                </Phone>
                <Experience><b>{t('contactPageMemberExperienceLabel')}</b> {t('contactPageManager1Experience')}</Experience>
                <Specialization>{t('contactPageManager1Description')}</Specialization>
                {/*<CarsCount><b>{t('contactPageMember1CarsCount')}</b> {t('contactPageMember1CarsCountLabel')}</CarsCount>*/}
              </InfoBlock>
            </TeamMember>
            <TeamMember>
              <MemberImageWrapper>
                <MemberImage
                  layout='fill'
                  alt='Andrii'
                  src='/static-web/images/contacts/ANDREY/ANDREY-cover.png'
                  // hoverUrl='/static-web/images/contacts/ANDREY/ANDREY.gif'
                />
              </MemberImageWrapper>
              <InfoBlock>
                <Name>{t('contactPageTestimonial2Title')}</Name>
                <Position>{t('contactPageMember4Position')}</Position>
                <Phone
                  onClick={() => {
                    simulateCall('+380962156759')
                  }}
                >
                  +38 (096) 215 67 59
                </Phone>
                <Experience><b>{t('contactPageMemberExperienceLabel')}</b> {t('contactPageMember4Experience')}</Experience>
                <Specialization>{t('contactPageMember4Description')}</Specialization>
                {/*<CarsCount><b>{t('contactPageMember4CarsCount')}</b> {t('contactPageMember4CarsCountLabel')}</CarsCount>*/}
              </InfoBlock>
            </TeamMember>
          </TeamWrapper>
        </PageContent>

        <Text
          sx={{
            ...sectionHeadingStyles,
            mt: '75px !important'
          }}
        >
          <Heading
            variant='heading.xl'
            color='text.secondary'
            fontFamily='secondary'
          >
            {t('contactPageTestimonialsTitle')}
          </Heading>
        </Text>

        <PageContent>
          <TestimonialsWrapper>
            <NavIcon
              id="previousButton"
              onClick={() => swiperRef.current.swiper.slidePrev()}
              sx={{
                left: '-15px',
              }}
            >
              <Arrow />
            </NavIcon>
            <NavIcon
              id="nextButton"
              onClick={() => swiperRef.current.swiper.slideNext()}
              sx={{
                right: '-15px',
              }}
            >
              <Arrow right />
            </NavIcon>
            <Swiper
              breakpoints={{
                1600: {
                  slidesPerView: 3
                },
                1200: {
                  slidesPerView: 2
                },
                600: {
                  slidesPerView: 1
                },
                400: {
                  slidesPerView: 1
                }
              }}
              ref={swiperRef}
            >
              {testimonials.map((testimonial, currentIndex) => {
                const translatedText = t(testimonial.text);
                const showArrow = translatedText.length > 280;
                const toggleTestimonial = () => {
                  setTestimonials(testimonials.map(t => {
                    if (testimonials.indexOf(t) === currentIndex) {
                      return {
                        ...t,
                        state: testimonial.state === 'expanded' ? 'collapsed' : 'expanded',
                        height: testimonial.state === 'expanded' ? 380 : testimonial.expandedHeight
                      }
                    }
                    return {
                      ...t
                    }
                  }))
                }
                return (
                  <SwiperSlide
                    key={`testimonial-${testimonial.id}`}
                  >
                    <Testimonial height={testimonial.height}>
                      <Box
                        sx={{
                          position: 'relative',
                          marginTop: '15px',
                          width: '100px',
                          height: '100px',
                          minWidth: '100px',
                          minHeight: '100px',
                        }}
                      >
                        <Image layout='fill' alt={testimonial.name} src={testimonial.image}/>
                      </Box>
                      <TestimonialTitle>{t(testimonial.name)}</TestimonialTitle>
                      <TestimonialText height={testimonial.height} fontFamily="primary" mt={3}>{translatedText}</TestimonialText>
                      {showArrow && (
                        <Flex
                          justifyContent="flex-end"
                          width='100%'
                          mt={2}
                          css={`
                          svg {
                            transition: transform 0.3s ease-in-out;
                            transform: ${testimonial.state === 'collapsed' ? 'rotate(-90deg)' : 'rotate(90deg)' };
                            &:hover {
                              cursor: pointer;
                            }
                          }
                        `}
                        >
                          <ArrowDown onClick={toggleTestimonial}/>
                        </Flex>
                      )}
                    </Testimonial>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </TestimonialsWrapper>
        </PageContent>
      </Section>
    </>
  );
};

const TestimonialText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 6;
  font-weight: bold;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  text-align: justify;

  height: ${({ height }) => height === 380 ? '100%' : '600px'}
`

const TestimonialTitle = styled(Text).attrs(() => ({
  fontFamily: 'primaryBold'
}))`
  margin-top: 10px;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  font-size: 24px;
`

const TestimonialsWrapper = styled(Flex)`
  justify-content: center;
  width: 100%;
  align-items: center;
  flex-wrap: nowrap;
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    flex-wrap: wrap;
  }
  .swiper-slide {
    min-width: 280px;
    padding: 35px;
    display: flex;
    justify-content: center;
  }
`

const Testimonial = styled(Flex)`
  transition: height 0.3s ease-in-out;
  background: #F6F7F9;
  padding: 25px;
  
  flex-direction: column;
  height: ${({ height }) => `${height}px`};
  align-items: center;
  width: 100%;
  
  img {
    border-radius: 50%;
    object-fit: cover;
  }
  
  max-width: 450px;
`

const PageContent = styled(Flex)`
  padding: 0;
  width: 100%;
  max-width: 1600px;
  position: relative;
  z-index: 99;
  flex-direction: column;

  @media (max-width: 1600px) {
    padding: ${({ theme }) => `0 ${theme.pageGutters[4]}`};
  }

  @media (max-width: 1200px) {
    padding: 0;
  }
`

const MemberImageWrapper = styled(Box)`
  position: relative;
  min-height: 390px;
  width: auto;
`

const MemberImage = styled(Image)`
  min-height: 390px;
  width: auto;
  object-fit: cover;
  background-image: ${({url}) => `url(${url})`};
  background-size: cover;
  background-position: center center;
  
  ${({hoverUrl}) => hoverUrl && css`
    &:hover {
      background-image: ${({hoverUrl}) => `url(${hoverUrl})`};
    }
  `}
`

const CarsCount = styled(Text).attrs(() => ({
  fontFamily: 'primary'
}))`
  margin: 10px auto 0;
  font-size: 14px;
  text-align: center;
`

const Specialization = styled(Text).attrs(() => ({
  fontFamily: 'primary'
}))`
  min-height: 55px;
  margin: 10px auto 0;
  font-size: 14px;
  text-align: center;
`

const Experience = styled(Text).attrs(() => ({
  fontFamily: 'primary'
}))`
  margin: 10px auto 0;
  font-size: 14px;
  text-align: center;
`

const Phone = styled(Text).attrs(() => ({
  fontFamily: 'primaryBold'
}))`
  font-weight: bold;
  font-size: 18px;
  margin: 15px auto 0;
  text-align: center;
  padding-bottom: 10px;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1200px) {
    font-size: 15px;
  }
`

const Position = styled(Text).attrs(() => ({
  fontFamily: 'primary'
}))`
  min-height: 36px;
  margin: 10px auto 0;
  font-size: 14px;
  text-align: center;
`

const Name = styled(Text).attrs(() => ({
  fontFamily: 'primaryBold'
}))`
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto;
  text-align: center;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
`

const InfoBlock = styled(Flex)`
  flex-direction: column;
  border-right: 1px solid #000;
  border-top: none;

  padding: 15px;
  min-height: 300px;
  height: 100%;

  :first-child {
    border-left: 1px solid #000;
  }
  
  @media (max-width: 900px) {
    border-bottom: 1px solid #000;
    border-left: 0.5px solid #000;
    border-right: 0.5px solid #000;
    border-top: none;
  }
`

const TeamMember = styled(Flex)`
  width: 100%;
  flex-direction: column;
`

const TeamWrapper = styled(Flex)`
  width: 100%;
  display: grid;
  border-bottom: 1px solid #000;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    border-bottom: none;
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const SubTitle = styled(Heading)`
  font-size: 14px;
  max-width: 66%;
  margin: 25px auto 0;
  word-wrap: break-word;
  @media (max-width: 900px) {
    max-width: 100%;
  }
`

const CarHeaderImage = styled(Image)`
  width: 100%;
  max-height: 820px;
  object-fit: cover;
  min-height: 325px;
  position: relative;
  @media (max-width: 1000px) {
    min-height: 325px;
  }
`

const ImageOverlay = styled(Box)`
  position: absolute;
  width: 100%;
  height: calc(100% - 2px);
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  min-height: 325px;
`

const ImageContainer = styled(Box)`
  width: 100%;
  height: fit-content;
`

const Section = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({heading}) => heading ? 0 : '75px 50px'};
  max-width: ${({fullWidth, theme}) => fullWidth ? '100%' : theme.breakpoints[2]};
  height: ${(({staticHeight}) => staticHeight ? `${staticHeight}px` : 'fit-content')};
  background: #fff;

  :not(:first-child) {
    margin: 0 auto;
  }

  ${({isSmMobile}) => isSmMobile && css`
    padding: ${({heading}) => heading ? 0 : '20px 20px 30px'};
  `}
`


export default Contact;

